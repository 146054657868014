

































import type { PropType } from "@nuxtjs/composition-api";
import { defineComponent, toRefs } from "@nuxtjs/composition-api";
import { SfSection } from "@storefront-ui/vue";
import { useImage, useProduct } from "~/composables";
import { useAddToCart } from "~/helpers/cart/addToCart";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import type { Product } from "~/modules/catalog/product/types";
import { useUser } from "~/modules/customer/composables/useUser";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import SfProductCard from "../../category/components/views/SFProductCard.vue";
import { useProductsWithCommonProductCardProps } from "../../category/components/views/useProductsWithCommonCardProps";

export default defineComponent({
	name: "ProductsCarousel",
	components: {
		SfProductCard,
		SfSection,
	},
	props: {
		title: {
			type: String,
			required: false,
			default: "",
		},
		products: {
			type: Array as PropType<Product[]>,
			required: false,
			default: () => [],
		},
		loading: Boolean,
		freeGift: {
			type: Boolean,
			required: false,
			default: false,
		},
		showAddToCartButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		promo: {
			type: Object,
			default: null,
		},
		showColorSwatch: {
			type: Boolean,
			default: false,
		},
		isFlashSale: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const { isAuthenticated } = useUser();
		const { isInWishlist, addOrRemoveItem } = useWishlist();
		const { addItemToCart, isInCart } = useAddToCart();
		const { getProductPath } = useProduct();
		const { products } = toRefs(props);
		const { productsWithCommonProductCardProps } =
			useProductsWithCommonProductCardProps(products);

		const addItemToWishlist = async (product) => {
			await addOrRemoveItem({ product });
		};

		const { getMagentoImage, imageSizes } = useImage();
		const showColor = props.showColorSwatch;
		const flashSale = props.isFlashSale;

		return {
			addItemToCart,
			addItemToWishlist,
			isAuthenticated,
			isInCart,
			isInWishlist,
			productGetters,
			getMagentoImage,
			imageSizes,
			getProductPath,
			productsWithCommonProductCardProps,
			showColor,
			flashSale,
		};
	},
});
