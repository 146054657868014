




































import {
	defineComponent,
	onMounted,
	ref,
	useContext,
	watch,
} from "@nuxtjs/composition-api";
import { SfButton } from "@storefront-ui/vue";
import moment from "moment-timezone";
import Countdown from "vuejs-countdown";
import CustomBlocks from "~/components/CustomBlocks.vue";
import Preload from "~/components/Preload.vue";
import { useProduct } from "~/composables";
import { convertToLocaleDateTime } from "~/helpers/moment";
import { SortEnum } from "~/modules/GraphQL/types";
import ProductsGrid from "~/modules/catalog/product/components/ProductsGrid.vue";
import LatestEvent from "./LatestEvent.gql";

export default defineComponent({
	name: "FlashSale",
	components: {
		Countdown,
		ProductsGrid,
		CustomBlocks,
		SfButton,
		Preload,
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	setup() {
		const flashSaleData = ref();
		const { app } = useContext();
		const { getProductList, loading } = useProduct();
		const productItems = ref();
		const productsWithCommonProductCardProps = ref();
		const items = ref();
		const productSkus = ref([]);
		const now = ref(moment());
		const endingTime = ref();
		const isLoadedFlashSale = ref(false);

		const queryFlashSale = async () => {
			isLoadedFlashSale.value = false;
			const { data } = await app.context.$vsf.$magento.api.customQuery({
				query: LatestEvent,
				queryVariables: {
					limit: 1,
					type: "ongoing",
				},
			});

			if (data?.latestFlashSale.length > 0) {
				flashSaleData.value = data?.latestFlashSale?.[0]?.items?.[0];
				endingTime.value = flashSaleData?.value?.event_to;
				productItems.value = flashSaleData?.value?.products;

				productItems.value.forEach((v, k) => {
					productSkus.value[k] = v.sku;
				});

				const baseSearchQuery = {
					pageSize: 8,
					sort: {
						position: SortEnum.Asc,
					},
					filter: {
						sku: {
							in: productSkus.value,
						},
					},
				};

				items.value = await getProductList(baseSearchQuery);

				await items.value.items.forEach((value, key) => {
					productItems.value.forEach((v, k) => {
						if (v.sku === value.sku) {
							items.value.items[key].qty_ordered = v.qty_ordered;
						}
					});
				});
			} else {
				items.value = [];
				flashSaleData.value = [];
				endingTime.value = null;
			}
			isLoadedFlashSale.value = true;
		};

		const convertDateTimezone = (value) => {
			return convertToLocaleDateTime(value);
		};

		const interval = () => {
			setInterval(() => {
				now.value = moment();
			}, 1000);
		};

		onMounted(async () => {
			await queryFlashSale();
			if (flashSaleData.value) {
				interval();
			}
		});

		watch(
			() => now.value,
			async (newValue) => {
				if (endingTime.value) {
					const end = moment
						.tz(endingTime.value, "UTC")
						.tz("Asia/Bangkok")
						.format("YYYY-MM-DD HH:mm:ss");
					if (!moment(now.value).isSameOrBefore(end)) {
						await queryFlashSale();
					}
				}
			},
		);
		// @ts-ignore
		return {
			flashSaleData,
			productsWithCommonProductCardProps,
			productItems,
			loading,
			items,
			convertDateTimezone,
			isLoadedFlashSale,
		};
	},
	data() {
		return {
			currentDate: new Date(),
		};
	},
});
