
































import type { PropType } from "@nuxtjs/composition-api";
import { defineComponent, ref } from "@nuxtjs/composition-api";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import { useConfig } from "~/composables";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import SfImage from "~/modules/catalog/product/components/SfImage.vue";

export default defineComponent({
	name: "OneLeftTwoRight",
	components: {
		VueSlickCarousel,
		SfImage,
	},
	props: {
		slides: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	setup(props) {
		const slidesData = ref(props.slides);
		const { config } = useConfig();
		const baseMediaUrl = config.value.secure_base_media_url;

		// Get the appropriate column class based on the key
		const getColumnClass = (key) => {
			return ["col-6", key === 0 ? "left-column" : "right-column"];
		};

		// Check if the item type is a banner
		const isBannerType = (type) => {
			return type === "0";
		};

		// Get the banner URL
		const getBannerUrl = (url) => {
			return url || "#";
		};

		// Get the banner image URL
		const getBannerImageUrl = (image) => {
			return `${baseMediaUrl}mageplaza/bannerslider/banner/image/${image}`;
		};

		return {
			slidesData,
			getColumnClass,
			isBannerType,
			getBannerUrl,
			getBannerImageUrl,
		};
	},
});
