import type { MetaInfo } from 'vue-meta';
import { useConfig } from '~/composables';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {

  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.meta_title || page?.title || page?.name;
    seoTags.meta.push({
      property: 'product:item_group_id',
      content: seoTags.title
    });
  }
  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description,
    });

    seoTags.meta.push({
      property: 'og:description',
      content: page.meta_description,
    });
  }

  if (page?.meta_keyword || page?.meta_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  if (page?.sku) {
    seoTags.meta.push({
      property: 'product:retailer_item_id',
      content: page.sku,
    });
  }
  if (page?.url) {
    seoTags.meta.push({
      property: 'og:url',
      content: page.url,
    });
  }
  if (page?.price_range?.minimum_price?.final_price?.value) {
    seoTags.meta.push({
      property: 'product:price:amount',
      content: page?.price_range?.minimum_price?.final_price?.value,
    });
    seoTags.meta.push({
      itemprop: 'price',
      content: page?.price_range?.minimum_price?.final_price?.value,
    });


    seoTags.meta.push({
      property: 'product:price:currency',
      content: page?.price_range?.minimum_price?.final_price?.currency,
    });

    seoTags.meta.push({
      itemprop: 'priceCurrency',
      content: page?.price_range?.minimum_price?.final_price?.currency,
    });
  }


  if (page?.stock_status) {
    seoTags.meta.push({
      property: 'product:availability',
      content: page?.stock_status,
    });
  }

  if (page?.image?.url) {
    seoTags.meta.push({
      property: 'og:image',
      content: page?.image?.url,
    });
    seoTags.meta.push({
      property: 'og:image:width',
      content: '200',
    });
    seoTags.meta.push({
      property: 'og:image:height',
      content: '200',
    });
  }

  if (page?.categories?.[0]) {
    seoTags.meta.push({
      property: 'product:category',
      content: page?.categories?.[0]?.name,
    });
  }

  return seoTags;
};
