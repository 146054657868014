



































































import axios from "axios";
import {
	defineComponent,
	onMounted,
	ref,
	useContext,
} from "@nuxtjs/composition-api";
import { useConfig } from "~/composables";
import Preload from "~/components/Preload.vue";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import SfImage from "~/modules/catalog/product/components/SfImage.vue";
export default defineComponent({
	name: "Blog",
	props: {
		category_id: {
			type: String,
			default: "",
		},
		category_name: {
			type: String,
			default: "",
		},
		blog_list: {
			type: Boolean,
			default: false,
		},
		search_query: {
			type: String,
			default: "",
		},
		posts_list: {
			type: Array,
			default: [],
		},
	},
	components: {
		Preload,
		VueSlickCarousel,
		SfImage,
	},
	setup(props) {
		const {
			localePath,
			localeRoute,
			app: { i18n },
		} = useContext();
		const { config } = useConfig();
		const baseMediaUrl = config.value.secure_base_media_url;
		const image_width = config.value.amblog_post_image_width;
		const image_height = config.value.amblog_post_image_height;
		const baseUrl = config.value.secure_base_url;
		const posts = ref([]);
		const postsData = ref([]);
		const isLoaded = ref(false);
		const blogList = ref(false);
		const searchQuery = ref(null);
		const currentPage = ref(1);
		const totalPageMobile = ref(0);

		// Function to generate the full URL for a blog post's image
		const postImageUrl = (imageName: string) => {
			const firstLetter = imageName.substring(0, 1);
			const secondLetter = imageName.substring(1, 2);
			return `${baseMediaUrl}amasty/blog/${imageName}`;
		};

		const customPaging = (slideIndex: number) => {
			return `<span>${slideIndex + 1}</span>`;
		};

		const checkCurrentPage = (page: number) => {
			if (currentPage.value === page) {
				return true;
			}
			return false;
		};
		const generateClassPage = (page: number) => {
			const classes = [];

			if (totalPageMobile.value <= 5) {
				return page;
			}

			if (page === currentPage.value + 1 || page === currentPage.value + 2) {
				classes.push("after-current-page");
			}

			if (page === currentPage.value - 1 || page === currentPage.value - 2) {
				classes.push("before-current-page");
			}

			if (page === 1) {
				classes.push("first-page");
			}

			if (page === totalPageMobile.value) {
				classes.push("last-page");
			}

			if (currentPage.value === page) {
				classes.push("current-page");
			}

			if (currentPage.value <= 4) {
				classes.push("active-first-page");
			}

			if (currentPage.value + 3 >= totalPageMobile.value) {
				classes.push("active-last-page");
			}
			if (page === totalPageMobile.value) {
				classes.push("last-page");
			}

			return classes.join(" ");
		};

		const handleBeforeChange = async (
			oldSlideIndex: number,
			newSlideIndex: number,
		) => {
			currentPage.value = newSlideIndex + 1;
			generateClassPage(currentPage.value);
		};

		// Fetch blog posts from the API on component mount
		onMounted(async () => {
			try {
				if (props.search_query) {
					searchQuery.value = props.search_query;
					const { data } = await axios.get(
						`${baseUrl}rest/V1/amasty_blog/search/${searchQuery.value}`,
						{
							headers: {
								Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
							},
						},
					);
					postsData.value = data;
					totalPageMobile.value = postsData?.value?.length;
					isLoaded.value = true;
				} else {
					postsData.value = props.posts_list;
					totalPageMobile.value = postsData?.value?.length;
				}

				isLoaded.value = true;
				blogList.value = props.blog_list;
			} catch (error) {
				isLoaded.value = true;
				console.error(error);
			}
		});

		return {
			posts,
			baseMediaUrl,
			postImageUrl,
			isLoaded,
			postsData,
			blogList,
			customPaging,
			searchQuery,
			image_width,
			image_height,
			generateClassPage,
			currentPage,
			checkCurrentPage,
			totalPageMobile,
			handleBeforeChange,
		};
	},
	data() {
		return {
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 1,
						rows: 1,
					},
				},
			],
		};
	},
});
