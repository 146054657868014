export default `
    query latestFlashSaleGql($limit: Int,$type: String) {
        latestFlashSale(limit:$limit,type:$type)
        {
            items {
                entity_id
                event_type
                event_from
                event_to
                event_name
                banner_html
                category_url_key
                category_uid
                products {
                    sku
                    qty_ordered
                    product_price
                    product_sale_price
                    product_discount
                }
                categories {
                    category_id
                    category_name
                    product_ids
                }
            }
           
        }
    }
`;
