import moment from "moment";
import 'moment/locale/th'; // Import Thai locale
import { useConfig } from "~/composables";

const convertToLocaleDateTime = (dateValue: any, format: string | null = "YYYY-MM-DD HH:mm:ss", timezone: string | null = null) => {
  const { config: storeConfig } = useConfig();
  // Set Thai locale as default
  moment.locale("th");
  return moment
    .tz(dateValue, "UTC")
    .tz(timezone || storeConfig.value.timezone)
    .format(format);
};

const convertToLocaleDateTime2 = (dateValue: any, timezone: string) => {
  return moment.tz(dateValue, "UTC").tz(timezone).format("YYYY-MM-DD HH:mm:ss");
};

export { convertToLocaleDateTime, convertToLocaleDateTime2 };
